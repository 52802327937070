import { EnvConfig } from "./envconfig";

export const environment: EnvConfig = {
  production: true,
  sbdBaseUrl: 'https://staging.suretybondsdirect.com',
  apiBaseUrl: 'https://staging.suretybondsdirect.com/admin',
  dougV2BaseUrl: 'https://staging.suretybondsdirect.com',
  titleBase: '(STG) Doug',
  shouldCathyBeChatty: true,
  apiPrefix: 'api/v4'
};
